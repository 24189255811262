import * as React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import Sidebar from '../components/sidebar'
import Footer from '../components/footer'
import Head from '../components/head'


export default function Main() {

 
  
  const [name,setName]=useState('')
  const [alt,setAlt]=useState('')
  const [title,setTitle]=useState('')
  const [id,setId]=useState('')
  const [type,setType]=useState('image_hotel')
  const [img,setImage]=useState('https://etihadtravels.co.uk/images/hotel/')

  useEffect(() => {
    get();
  }, []);


  const [all,setAll]=useState([])
  
  const getAllImages= async (id)=> {
    const data = new FormData();
    
    data.append('id',id);


            axios({
              method: 'post',
              url: '/api/hotel_img/view.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {

                setAll(s.data)
             
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const delete_now= async (id)=> {
    const data = new FormData();
    
    data.append('id',id);


            axios({
              method: 'post',
              url: '/api/hotel_img/delete.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {

                getAllImages(id)
             
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const get =()=> {
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get("id")
    const name = queryParams.get("hotel_name")
    setId(id)
    setName(name)
    getAllImages(id)
    

    };

  const getDb = async ()=> {

    const data = new FormData();  
    data.append('alt',alt);
    data.append('title',title);
    data.append('get_id',id);
    data.append('type',type);
    data.append('img',img);



            axios({
              method: 'post',
              url: '/api/hotel_img/add.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               
                console.log("run")
                if(s.data[0].msg=="already")
                {
                  alert("already SEO here")

                }else if(s.data[0].msg=="fail")
                  {
                    alert("Fail here")
                  }else{
                    alert("Saved here")
                  }
                  getAllImages(id)
               
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


  return (
    <>
     <div className="main-wrapper">
        <Sidebar />
        <div className="page-wrapper">
       
       <Head />
       <div>
    

{/* 2 */}
        <div className="page-content">
          {/* view star */}

          <div className="row">
    <div className="col-md-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <table id="dataTableExample" className="table">
              <thead>
                <tr>
                  
                  <th>ID</th>
                  <th>Img</th>
                  <th>Alt</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>

              </thead>
              <tbody>
                {
                  all.map((v)=>(
<tr>
                  
                  <td>{v.id}</td>
                  <td>{v.img}</td>
                  
                  <td>{v.alt}</td>

                  <td>{v.t}</td>
                  <td>{v.des}</td>
                
                  <td> 
                    <button onClick={()=>{
                      delete_now(v.id)
                    }}  className="btn btn-primary">Delete</button>
                    
                    </td>
                  
                  </tr>
                  ))
                }
                
             
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
          {/* view End */}
    <div className="row">
    <div className="col-md-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4">Add Hotel Image</h4>
         
            <div className="row">
            
              <div className="col-lg-8 mb-3">
              <label htmlFor="fullname" className="form-label">
                  
                  Hotel Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullname"
                  disabled
                  autoComplete="off"
                  placeholder=""
                  value={name} onChange={(v)=>{ setName(v.target.value)}}
                />
              </div>
              <div className="col-lg-4 mb-3">
              <label htmlFor="fullname" className="form-label">
                Type
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullname"
                  autoComplete="off"
                  placeholder=""
                  disabled
                  value={type} onChange={(v)=>{ setType(v.target.value)}}
                />
              </div>
              <div className="col-lg-8 mb-3">
              
              
                <label htmlFor="fullname" className="form-label">
                  
                  Img File 
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullname"
                  autoComplete="off"
                  placeholder=""
                  value={img} onChange={(v)=>{ setImage(v.target.value)}}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label htmlFor="email" className="form-label">Alt</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  autoComplete="off"
                  placeholder=""
                  value={alt} onChange={(v)=>{ setAlt(v.target.value)}}
                />
              </div>

              <div className="col-lg-6 mb-3">
                <label htmlFor="dd" className="form-label">Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="dd"
                  autoComplete="off"
                  placeholder=""
                  value={title} onChange={(v)=>{ setTitle(v.target.value)}}
                />
              </div>
   

             
              <div>
                <button  className="btn btn-primary" onClick={()=>{
                  getDb();
                }} >
                  <i className="link-icon" data-feather="plus" />Image Hotel</button>
              </div>
            </div>
         
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
     
 
       
        <Footer/>
       
      </div>
    </div>
    
  </>

  )
}

import React from 'react'
import logo from '../img/logo.png';

export default function sidebar() {
  return (
    <>
    <nav className="sidebar">
      <div className="sidebar-header">
        <a href="../index.html" className="sidebar-brand">
          <img src={logo} alt="logo" className="w-75" />
        </a>
        <div className="sidebar-toggler not-active">
          <span />
          <span />
          <span />
          
        </div>
      </div>
      <div className="sidebar-body">
        <ul className="nav">
          <li className="nav-item active">
            <a href="dashboard.html" className="nav-link">
              <i className="link-icon" data-feather="box" />
              <span className="link-title">Dashboard</span>
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="collapse"
              href="#category"
              role="button"
              aria-expanded="false"
              aria-controls="category"
            >
              <i className="link-icon" data-feather="table" />
              <span className="link-title">SEO</span>
              <i className="link-arrow" data-feather="chevron-down" />
            </a>
            <div className="collapse" id="category">
              <ul className="nav sub-menu">
                <li className="nav-item">
                  <a href="addseo" className="nav-link">
                    Add SEO
                  </a>
                </li>
                <li className="nav-item">
                  <a href="viewseo" className="nav-link">
                    View SEO
                  </a>
                </li>
               
              </ul>
            </div>
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="collapse"
              href="#hotel"
              role="button"
              aria-expanded="false"
              aria-controls="hotel"
            >
              <i className="link-icon" data-feather="list" />
              <span className="link-title">Hotel</span>
              <i className="link-arrow" data-feather="chevron-down" />
            </a>
            <div className="collapse" id="hotel">
              <ul className="nav sub-menu">
                <li className="nav-item">
                  <a href="addhotel" className="nav-link">
                    Add Hotel
                  </a>
                </li>
                <li className="nav-item">
                  <a href="viewhotel" className="nav-link">
                    View Hotel
                  </a>
                </li>
               
              </ul>
            </div>
          </li>

          {/* <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="collapse"
              href="#hotelimg"
              role="button"
              aria-expanded="false"
              aria-controls="hotel">
              <i className="link-icon" data-feather="image" />
              <span className="link-title">Image Hotel</span>
              <i className="link-arrow" data-feather="chevron-down" />
            </a>
            <div className="collapse" id="hotelimg">
              <ul className="nav sub-menu">
                <li className="nav-item">
                  <a href="addhotel" className="nav-link">
                    Add Image Hotel
                  </a>
                </li>
                <li className="nav-item">
                  <a href="viewhotel" className="nav-link">
                    View Image Hotel
                  </a>
                </li>
               
              </ul>
            </div>
          </li> */}



<li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="collapse"
              href="#events"
              role="button"
              aria-expanded="false"
              aria-controls="events"
            >
              <i className="link-icon" data-feather="user" />
              <span className="link-title">Package</span>
              <i className="link-arrow" data-feather="chevron-down" />
            </a>
            <div className="collapse" id="events">
              <ul className="nav sub-menu">
                <li className="nav-item">
                  <a href="listpackage" className="nav-link">
                    All Package Lists
                  </a>
                </li>
                <li className="nav-item">
                  <a href="addpackage" className="nav-link">
                    Add Package
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <a href='login'  className="nav-link" 
            onClick={()=>{
                    localStorage.clear()
                    
                    }}>
              <i className="link-icon" data-feather="log-out" />
              <span className="link-title">Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
    </>
  )
}

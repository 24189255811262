
import React, { useState ,useEffect} from 'react';

export default function Head() {
  useEffect(() => {
    const access=JSON.parse(localStorage.getItem("admin"))
    if(access==null)
    {
      window.location.replace("/login");
    }
  }, []);

  return (
    <>
     <nav className="navbar">
        <a href="#" className="sidebar-toggler">
          <i data-feather="menu" />
        </a>
        <div className="navbar-content">
          <form className="search-form w-25">
            <div className="input-group">
              <div className="input-group-text">
                <i data-feather="search" />
              </div>Welcome to Kashif
              
            </div>
          </form>
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="notificationDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i data-feather="bell" />
                <div className="indicator">
                  <div className="circle" />
                </div>
              </a>
              <div
                className="dropdown-menu p-0"
                aria-labelledby="notificationDropdown"
              >
                <div className="px-3 py-2 d-flex align-items-center justify-content-between border-bottom">
                  <p>6 New Notifications</p>
                  <a className="text-muted">Clear all</a>
                </div>
                <div className="p-1">
                  <a className="dropdown-item d-flex align-items-center py-2">
                    <div className="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                      <i className="icon-sm text-white" data-feather="gift" />
                    </div>
                    <div className="flex-grow-1 me-2">
                      <p>New Order Recieved</p>
                      <p className="tx-12 text-muted">30 min ago</p>
                    </div>
                  </a>
                  <a className="dropdown-item d-flex align-items-center py-2">
                    <div className="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                      <i
                        className="icon-sm text-white"
                        data-feather="alert-circle"
                      />
                    </div>
                    <div className="flex-grow-1 me-2">
                      <p>Server Limit Reached!</p>
                      <p className="tx-12 text-muted">1 hrs ago</p>
                    </div>
                  </a>
                </div>
                <div className="px-3 py-2 d-flex align-items-center justify-content-center border-top">
                  <a>View all</a>
                </div>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="profileDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  className="wd-30 ht-30 rounded-circle"
                  src="../images/reviewer/1.jpg"
                  alt="profile"
                />
              </a>
              <div
                className="dropdown-menu p-0"
                aria-labelledby="profileDropdown"
              >
                <div className="d-flex flex-column align-items-center border-bottom px-5 py-3">
                  <div className="mb-3">
                    <img
                      className="wd-80 ht-80 rounded-circle"
                      src="../images/reviewer/1.jpg"
                      alt=""
                    />
                  </div>
                  <div className="text-center">
                    <p className="tx-16 fw-bolder">Nelson Kelly Burton</p>
                    <p className="tx-12 text-muted">nelsonn@gmail.com</p>
                  </div>
                </div>
                <ul className="list-unstyled p-1">
                  <li className="dropdown-item py-2">
                    <a href="profile.html" className="text-body ms-0">
                      <i className="me-2 icon-md" data-feather="user" />
                      <span>Profile</span>
                    </a>
                  </li>
                  <li className="dropdown-item py-2">
                    <a className="text-body ms-0">
                      <i className="me-2 icon-md" data-feather="edit" />
                      <span>Edit Profile</span>
                    </a>
                  </li>
                  <li className="dropdown-item py-2">
                    <a className="text-body ms-0">
                      <i className="me-2 icon-md" data-feather="repeat" />
                      <span>Switch User</span>
                    </a>
                  </li>
                  <li className="dropdown-item py-2">
                    <a className="text-body ms-0">
                      <i className="me-2 icon-md" data-feather="log-out" />
                      <span>Log Out</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}

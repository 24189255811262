import * as React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import Sidebar from '../components/sidebar'
import Footer from '../components/footer'
import Head from '../components/head'


export default function Main() {




  const [packages,setpackages]=useState([])
  const [allmonth,setallmonth]=useState([])

  const [month_id,setmonth_id]=useState('')


  

  useEffect(() => {
    getAllMonth();
  }, []);



  const getAllMonth = async ()=> {
    const data = new FormData();
    
    data.append('name',"nme");


            axios({
              method: 'post',
              url: '/api/package/all_month_type.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               
                
            
                setallmonth(s.data)
                console.log(s.data)
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };




  const getselected_pacakge = async (v)=> {
    const data = new FormData();
    
    data.append('slug',v);


            axios({
              method: 'post',
              url: '/api/package/allpackages_view.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               
                
            
                setpackages(s.data)
                console.log(s.data)
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };



  return (
    <>
     <div className="main-wrapper">
        <Sidebar />
        <div className="page-wrapper">
       
       <Head />
       <div>
    

{/* 2 */}
        <div className="page-content">
          <h2>List of Packages</h2>
          <br/>
         <div className='card'>
         <div class="card-body">
         <div className='row' >
            <div className='col-12' style={{padding:"4px"}}>
              Select Page / Type
              </div>
              </div>
         <div className='row' >
            <div className='col-4'>
              
              <select value={month_id} onChange={(v)=>{ setmonth_id(v.target.value); }} className='form-control'>
               <option></option>

               {
                allmonth.map((v)=>(
                  <option value={v.slug}>{v.name}</option>
                ))
               }
               
              
               </select>
            </div>
            <div className='col-4'>
            <div>
            
                <button  className="btn btn-primary" onClick={()=>{
                  getselected_pacakge(month_id);
                }} >
                  <i className="link-icon" data-feather="plus" /> Get Package</button>
              </div>
            </div>
          </div>
          </div>
         </div>
          <br/>
        

        {
          packages.map((v,i)=>(
            <div>
            <div className="card">
            <div className="card-body">
              
            <div className="row">
            <div className='col-1' >
            <b>Count {i+1}</b>
            </div>
            <div className='col-1' >
            <b>ID {v.id}</b>
            </div>


             
<div className='col-3' >
Type : <b style={{color:"DarkBlue"}}>{ v.package_type_name}</b>

  </div>

<div className='col-3' >
  
  Flight / Visa / Transport / Review
 
  </div>
<div className='col-3' >

  {
    v.flight=="1"?" Yes ":" No "
  } /
  {
    v.visa=="1"?" Yes ":" No "
  }
  /
  {
    v.transport=="1"?" Yes ":" No "
  }
  /
  {
    v.review
  }  <a href={"updatepackage?id="+v.id+"&m="+v.month_id}> <button className='btn btn-primary' > Edit</button>
                </a>
              

  
  </div>

  <div> <br/> <hr/> </div>
{/*  */}
              <div className='col-2' >
               <h4 style={{color:"Indigo"}}> Package Title </h4>
              </div>
              <div className='col-4' >
               <h3 style={{color:"Coral"}}> {
                  v.name
                }</h3>
              </div>
              

              <div className='col-3' >
               <h3 style={{color:"Coral"}}>{v.month_name}</h3>
              </div>

              <div className='col-12' >
                <br/>
                <h4>Description</h4>
                <p>
                  {
                    v.des
                  }
                </p>
                <hr/>
                </div>
              <div className='col-6' >
                <br/>
                <h4>Image Path</h4>
                <p>
                  {
                    v.img
                  }
                </p>
                <hr/>
                </div>
              <div className='col-6' >
                <br/>
                <h4>Alt</h4>
                <p>
                  {
                    v.alt
                  }
                </p>
                <hr/>
                </div>
              <div className='col-6' >
                <br/>
                <h4>Img Title</h4>
                <p>
                  {
                    v.img_des
                  }
                </p>
                <hr/>
                </div>
               

              <div className='col-3' >
                Makkah nights
                </div>

              <div className='col-3' >
               {
                v.makkah_nights
               }
                </div>

              <div className='col-3' >
                Madinah nights
                </div>

              <div className='col-3' >
              {
                v.madinah_nights
               }
                </div>

              <div className='col-3' >
                Extra nights
                </div>

              <div className='col-3' >
              {
                v.extra_nights
               }
                </div>
              <div className='col-3' >
              Total Nights
                </div>
              <div className='col-3' >
                {
                  v.total_nights
                }
                
                </div>
                <div> <br/>  <hr/> </div>
              <div className='col-2' >
                Hotel Makkah
                
                </div>

              <div className='col-3' >
                {
                  v.hotel_makkah_name
                }
                </div>

                <div className='col-2' >
              Hotel Madinah
                </div>

              <div className='col-3' >
                {
                  v.hotel_madinah_name
                }
                </div>
           
              <div className='col-2' >
                <h6>Extra Hotel: {v.hotel_extra_name} / Country : {v.extra_country} </h6>
                
                </div>


          


              
              




              </div>
              


            </div>
            </div>
            <br/>
            </div>

          ))
        }
    
    

</div>

    </div>
     
  
       
        <Footer/>
       
      </div>
    </div>
  </>

  )
}

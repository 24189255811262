

import { BrowserRouter, Routes, Route } from "react-router-dom"
import React, { useEffect } from 'react';
import Login from './login';
import Main from './main';
import AddUser from './user/adduser';
import UpdateUser from './user/updateuser';

// SEO
import AddSeo from './seo/add';
import UpdateSeo from './seo/update';
import ViewSEo from './seo/view';

// Hotel
import AddHotel from './hotels/add';
import UpdateHotel from './hotels/update';
import ViewHotel from './hotels/view';

// Hotel-Img
import AddHotelImg from './hotel_img/add';

// package
import AddPackage from './packages/add';
import ViePackage from './packages/view';
import UpdatePackage from './packages/update';





export default function IconLabelButtons() {
  
  return (<>
    <BrowserRouter basename="/">
    <Routes>
      
      <Route  >
        {/* Common URLs */}
        <Route index element={<> <Login />  </>} />
        <Route path="*" element={<> <Login /> </>} />
        <Route path="/login" element={<Login />} />
        {/* Dashboard */}

        <Route path="/main"   element={<> <Main /> </>} />

        {/* user */}
        <Route path="/newuser" element={<AddUser />} />
        <Route path="/updateuser" element={<UpdateUser />} />

        {/* SEO */}
        <Route path="/addseo" element={<AddSeo />} />
        <Route path="/updateseo" element={<UpdateSeo />} />
        <Route path="/viewseo" element={<ViewSEo />} />
        
        {/* Hotel */}
        <Route path="/addhotel" element={<AddHotel />} />
        <Route path="/updatehotel" element={<UpdateHotel />} />
        <Route path="/viewhotel" element={<ViewHotel />} />



        {/* Hotel Image */}
        <Route path="/addhotelimg" element={<AddHotelImg />} />

        {/* Package */}
        <Route path="/addpackage" element={<AddPackage />} />
        <Route path="/listpackage" element={<ViePackage />} />
        <Route path="/updatepackage" element={<UpdatePackage />} />




        </Route>
    </Routes>    
  </BrowserRouter>
  </>
  );
}

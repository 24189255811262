import * as React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import Sidebar from '../components/sidebar'
import Footer from '../components/footer'
import Head from '../components/head'


export default function Main() {




  const [seo,setSeo]=useState([])

  

  useEffect(() => {
    getAllseo();
  }, []);




  const getAllseo = async ()=> {
    const data = new FormData();
    
    data.append('name',"nme");


            axios({
              method: 'post',
              url: '/api/hotel/view.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               
                
            
                setSeo(s.data)
                console.log(s.data)
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };



  return (
    <>
     <div className="main-wrapper">
        <Sidebar />
        <div className="page-wrapper">
       
       <Head />
       <div>
    

{/* 2 */}
        <div className="page-content">
        <div className="row">
    <div className="col-md-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <table id="dataTableExample" className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  {/* <th>des</th> */}
                  <th>Extra</th>
                  <th>img</th>
                  <th>Star</th>
                  <th>Review</th>
                
                </tr>
              </thead>
              <tbody>
                {
                  seo.map((v)=>(
<tr>
                  
                  <td>{v.id}</td>
                  <td>{v.name}</td>
                  {/* <td>{v.des}</td> */}
                  <td>{v.ex}</td>

                  <td>{v.img}</td>
                  <td>{v.star}</td>
                  <td>{v.review}</td>
                  <td> <a href={"updatehotel?id="+v.id}> 
                    <button  className="btn btn-primary">Edit</button>
                    </a>
                    </td>
                  <td> <a href={"addhotelimg?id="+v.id+"&hotel_name="+v.name}> 
                    <button  className="btn btn-primary">Images</button>
                    </a>
                    </td>
                  
                  </tr>
                  ))
                }
                
             
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

    </div>
     
  
       
        <Footer/>
       
      </div>
    </div>
  </>

  )
}

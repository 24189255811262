import * as React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import Sidebar from '../components/sidebar'
import Footer from '../components/footer'
import Head from '../components/head'


export default function Main() {

  const [access,setAccess]=useState([{a1:false,a2:false,a3:false,a4:false,a5:false,a6:false,a7:false,a8:false,a9:false,a10:false,a11:false,a12:false,a13:false,a14:false,a15:false,a16:false,a17:false,a18:false,a19:false,a20:false,a21:false,a22:false,a23:false,a24:false,a25:false,a26:false,a27:false,a28:false,a29:false,a30:false,a31:false,a32:false,a33:false}])
  const [name,setName]=useState("")
  const [pass,setPassword]=useState('')
  const [username,setUsername]=useState('')


  const [user_list,setUserList]=useState([])

  

  useEffect(() => {
    getAllUser();
  }, []);


  const updateObject = ( keyToUpdate, newValue) => {
    setAccess(prevData => {
      const newData = [...prevData]; // Create a copy of the array
      
        newData[0] = { ...newData[0], [keyToUpdate]: newValue };
        
        return newData; // Update the state with the new array
    });
  };

  const getAllUser = async ()=> {
    const data = new FormData();
    
    data.append('name',name);


            axios({
              method: 'post',
              url: '/api/user/user_list.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               
                
                
          
                setUserList(s.data)
                console.log(s.data)
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const getDb = async ()=> {
    const data = new FormData();
    
    data.append('name',name);
    data.append('email',username);
    data.append('pass',pass);
    data.append('access',JSON.stringify(access));

            axios({
              method: 'post',
              url: '/api/user/user_add.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               
                console.log("run")
                if(s.data[0].msg=="already")
                {
                  alert("already User here")
                }else if(s.data[0].msg=="fail")
                  {
                    alert("fail")
                  }else{
                    alert("Saved")
                  }
               
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


  return (
    <>
     <div className="main-wrapper">
        <Sidebar />
        <div className="page-wrapper">
       
       <Head />
       <div>
    

{/* 2 */}
        <div className="page-content">
        <div className="row">
    <div className="col-md-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <table id="dataTableExample" className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Username</th>
                  <th>Password</th>
                
                </tr>
              </thead>
              <tbody>
                {
                  user_list.map((v)=>(
<tr>
                  
                  <td>{v.id}</td>
                  <td>{v.name}</td>
                  <td>{v.email}</td>
                  <td>{v.pass}</td>
                  </tr>
                  ))
                }
                
             
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-md-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4">New User </h4>
         
            <div className="row">
            
              <div className="col-lg-6 mb-3">
                <label htmlFor="fullname" className="form-label">
                  {" "}
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullname"
                  autoComplete="off"
                  placeholder=""
                  value={name} onChange={(v)=>{ setName(v.target.value)}}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label htmlFor="email" className="form-label">
                Email & Username
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  autoComplete="off"
                  placeholder=""
                  value={username} onChange={(v)=>{ setUsername(v.target.value)}}
                />
              </div>
              
            
              <div className="col-lg-6 mb-3">
                <label htmlFor="password" className="form-label">
                  {" "}
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  autoComplete="off"
                  placeholder=""
                  value={pass} onChange={(v)=>{ setPassword(v.target.value)}}
                />
              </div>
<h5>
              <div className="col-lg-12 mb-3">
                <p className="mb-2">Access Set</p>
                <div className="radio-put d-flex align-items-center align-middle pt-3">
                  <div className="form-check form-check-inline">
                  <label>
        <input
          type="checkbox"
          id="a1" checked={access[0].a1} onChange={()=>{ updateObject('a1',!(access[0].a1)) }}
        /> Packages Add / Update
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                  <label>
        <input
          type="checkbox"
          id="a2" checked={access[0].a2} onChange={()=>{ updateObject('a2',!(access[0].a2)) }}
        /> SEO ( Title , Meta Description , H1 ,H2 ,H3 ,H4 ,H5 ,H6  )
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                  <label>
        <input
          type="checkbox"
          id="a3" checked={access[0].a3} onChange={()=>{ updateObject('a3',!(access[0].a3)) }}
        /> On Page Content
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                  <label>
        <input
          type="checkbox"
          id="a4" checked={access[0].a4} onChange={()=>{ updateObject('a4',!(access[0].a4)) }}
        /> New User
                    </label>
                 

                  </div>
              
                 
                </div>
              </div>
              <div className="col-lg-12 mb-3">
                
              <div className="form-check form-check-inline">
                  <label>
        <input
          type="checkbox"
          id="a5" checked={access[0].a5} onChange={()=>{ updateObject('a5',!(access[0].a5)) }}
        /> Blogs Packages Add 
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                  <label>
        <input
          type="checkbox"
          id="a6" checked={access[0].a6} onChange={()=>{ updateObject('a6',!(access[0].a6)) }}
        /> Blogs Add
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                  <label>
        <input
          type="checkbox"
          id="a7" checked={access[0].a7} onChange={()=>{ updateObject('a7',!(access[0].a7)) }}
        /> Blogs Publish
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                  <label>
        <input
          type="checkbox"
          id="a8" checked={access[0].a8} onChange={()=>{ updateObject('a8',!(access[0].a8)) }}
        /> Blogs Update
                    </label>
                  </div>

                  {/* <div className="form-check form-check-inline">
                  <label>
        <input
          type="checkbox"
          id="a1" checked={access[0].a1} onChange={()=>{ updateObject('a1',!(access[0].a1)) }}
        /> Packages Add / Update
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                  <label>
        <input
          type="checkbox"
          id="a1" checked={access[0].a1} onChange={()=>{ updateObject('a1',!(access[0].a1)) }}
        /> Packages Add / Update
                    </label>
                  </div> */}
              </div>

              </h5>
             
              <div>
                <button  className="btn btn-primary" onClick={()=>{
                  getDb();
                }} >
                  <i className="link-icon" data-feather="plus" /> New User
                </button>
              </div>
            </div>
         
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
     
  
       
        <Footer/>
       
      </div>
    </div>
  </>

  )
}

import * as React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import Sidebar from '../components/sidebar'
import Footer from '../components/footer'
import Head from '../components/head'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS for styling




export default function Main() {






  const [url,setUrl]=useState("")
  const [title,setTitle]=useState('')
  const [des,setDes]=useState('')
  const [key,setKey]=useState('')
  const [h1,setH1]=useState('')
  const [h1des,setH1des]=useState('')
  const [h2_a,setH2_a]=useState('')
  const [h2_b,setH2_b]=useState('')
  const [h2_c,setH2_c]=useState('')
  const [ex1,setEx1]=useState('')
  const [ex2,setEx2]=useState('')
  const [code,setCode]=useState('')

  const [contentAsMsWord, setContentAsMsWord] = useState('');
  const handleChange = (value) => {
    setCode(value);
  };

  const getDb = async ()=> {
    const data = new FormData();
    
    data.append('url',url);
    data.append('t',title);
    data.append('des',des);
    data.append('h1',h1);
    data.append('h1_des',h1des);
    data.append('h2_a',h2_a);
    data.append('h2_b',h2_b);
    data.append('h2_c',h2_c);
    data.append('ex1',ex1);
    data.append('ex2',ex2);
    data.append('code',code);
    data.append('keyword',key);
 


            axios({
              method: 'post',
              url: '/api/seo/add.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               
                console.log("run")
                if(s.data[0].msg=="already")
                {
                  alert("already SEO here")

                }else if(s.data[0].msg=="fail")
                  {
                    alert("Fail here")
                  }else{
                    alert("Saved here")
                  }
               
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


  return (
    <>
     <div className="main-wrapper">
        <Sidebar />
        <div className="page-wrapper">
       
       <Head />
       <div>
    

{/* 2 */}
        <div className="page-content">
    <div className="row">
    <div className="col-md-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4">New SEO </h4>
         
            <div className="row">
            
              <div className="col-lg-6 mb-3">
                <label htmlFor="fullname" className="form-label">
                  {" "}
                  URL
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullname"
                  autoComplete="off"
                  placeholder=""
                  value={url} onChange={(v)=>{ setUrl(v.target.value)}}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label htmlFor="email" className="form-label">
                Meta Title (50–60 characters.)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  autoComplete="off"
                  placeholder=""
                  value={title} onChange={(v)=>{ setTitle(v.target.value)}}
                />
              </div>
              
            
              <div className="col-lg-12 mb-3">
                <label htmlFor="password" className="form-label">
                  
                  Meta Description ( between 50 — 160 characters )
                </label>
                <textarea
                  className="form-control"
                  onChange={(v)=>{ setDes(v.target.value)}}
                >{des}</textarea>
              </div>

              <div className="col-lg-12 mb-3">
                <label htmlFor="password" className="form-label">
                    All keywords
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  autoComplete="off"
                  placeholder=""
                  value={key} onChange={(v)=>{ setKey(v.target.value)}}
                />
              </div>
              <div className="col-lg-12 mb-3">
                <label htmlFor="password" className="form-label">
                    H1
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  autoComplete="off"
                  placeholder=""
                  value={h1} onChange={(v)=>{ setH1(v.target.value)}}
                />
              </div>
              <div className="col-lg-12 mb-3">
                <label htmlFor="password" className="form-label">
                    H1 des
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  autoComplete="off"
                  placeholder=""
                  value={h1des} onChange={(v)=>{ setH1des(v.target.value)}}
                />
              </div>
              <div className="col-lg-12 mb-3">
                <label htmlFor="password" className="form-label">
                    H2 A
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  autoComplete="off"
                  placeholder=""
                  value={h2_a} onChange={(v)=>{ setH2_a(v.target.value)}}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label htmlFor="password" className="form-label">
                H2 B
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  autoComplete="off"
                  placeholder=""
                  value={h2_b} onChange={(v)=>{ setH2_b(v.target.value)}}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label htmlFor="password" className="form-label">
                H2 C
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  autoComplete="off"
                  placeholder=""
                  value={h2_c} onChange={(v)=>{ setH2_c(v.target.value)}}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label htmlFor="password" className="form-label">
                    EX 1
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  autoComplete="off"
                  placeholder=""
                  value={ex1} onChange={(v)=>{ setEx1(v.target.value)}}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label htmlFor="password" className="form-label">
                    EX 2
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  autoComplete="off"
                  placeholder=""
                  value={ex2} onChange={(v)=>{ setEx2(v.target.value)}}
                />
              </div>
              <div className="col-lg-12 mb-3">
               
              <div dangerouslySetInnerHTML={{ __html: code }} />
              </div>



        <ReactQuill
        theme="snow"
        value={code}
        onChange={handleChange}
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, false] }], // Headers
            [{ font: [] }], // Font family
            [{ size: [] }], // Font size
            ['bold', 'italic', 'underline', 'strike', 'blockquote'], // Basic styling
            [
              { color: [] }, 
              { background: [] }
            ], // Text color and background color
            [{ script: 'sub' }, { script: 'super' }], // Subscript / Superscript
            [{ list: 'ordered' }, { list: 'bullet' }], // Lists
            [{ align: [] }], // Text alignment
            ['link', 'image', 'video'], // Media links
            ['clean'], // Clear formatting
          ],
        }}
        formats={[
          'header', 'font', 'size',
          'bold', 'italic', 'underline', 'strike', 'blockquote',
          'color', 'background',
          'script', 'list', 'bullet',
          'align', 'link', 'image', 'video',
        ]}
        style={{ height: '400px', width: '1600px' }}
      />
             
              <div>
                <button style={{margin:"120px"}}  className="btn btn-primary" onClick={()=>{
                  getDb();
                }} >
                  <i className="link-icon" data-feather="plus" /> SEO Deta
                </button>
              </div>
            </div>
         
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
     
  
       
        <Footer/>
       
      </div>
    </div>
  </>

  )
}

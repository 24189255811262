import React, { useState ,useEffect} from 'react';
import Sidebar from './components/sidebar'
import Footer from './components/footer'
import Head from './components/head'


export default function Main() {
  
  



  return (
    <>
     <div className="main-wrapper">
        <Sidebar />
        <div className="page-wrapper">
       
       <Head />
       
       <div className="page-content">
        <div className="d-flex justify-content-between align-items-center flex-wrap grid-margin">
          <div>
            <h4 className="mb-3 mb-md-0">
              Welcome to Kashif bhai 
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-12 stretch-card">
            <div className="row flex-grow-1">
              <div className="col-md-4 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-baseline">
                      <h6 className="card-title mb-0">New Customers</h6>
                      <div className="dropdown mb-2">
                        <button
                          className="btn p-0"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            className="icon-lg text-muted pb-3px"
                            data-feather="more-horizontal"
                          />
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a className="dropdown-item d-flex align-items-center">
                            <i data-feather="eye" className="icon-sm me-2" />{" "}
                            <span className="">View</span>
                          </a>
                          <a className="dropdown-item d-flex align-items-center">
                            <i data-feather="edit-2" className="icon-sm me-2" />{" "}
                            <span className="">Edit</span>
                          </a>
                          <a className="dropdown-item d-flex align-items-center">
                            <i data-feather="trash" className="icon-sm me-2" />{" "}
                            <span className="">Delete</span>
                          </a>
                          <a className="dropdown-item d-flex align-items-center">
                            <i
                              data-feather="printer"
                              className="icon-sm me-2"
                            />{" "}
                            <span className="">Print</span>
                          </a>
                          <a className="dropdown-item d-flex align-items-center">
                            <i
                              data-feather="download"
                              className="icon-sm me-2"
                            />{" "}
                            <span className="">Download</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-12 col-xl-5">
                        <h3 className="mb-2">1,297</h3>
                        <div className="d-flex align-items-baseline">
                          <p className="text-success">
                            <span>+2.5%</span>
                            <i
                              data-feather="arrow-up"
                              className="icon-sm mb-1"
                            />
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-12 col-xl-7">
                        <div id="customersChart" className="mt-md-3 mt-xl-0" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-baseline">
                      <h6 className="card-title mb-0">New Destination</h6>
                      <div className="dropdown mb-2">
                        <button
                          className="btn p-0"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            className="icon-lg text-muted pb-3px"
                            data-feather="more-horizontal"
                          />
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <a className="dropdown-item d-flex align-items-center">
                            <i data-feather="eye" className="icon-sm me-2" />{" "}
                            <span className="">View</span>
                          </a>
                          <a className="dropdown-item d-flex align-items-center">
                            <i data-feather="edit-2" className="icon-sm me-2" />{" "}
                            <span className="">Edit</span>
                          </a>
                          <a className="dropdown-item d-flex align-items-center">
                            <i data-feather="trash" className="icon-sm me-2" />{" "}
                            <span className="">Delete</span>
                          </a>
                          <a className="dropdown-item d-flex align-items-center">
                            <i
                              data-feather="printer"
                              className="icon-sm me-2"
                            />{" "}
                            <span className="">Print</span>
                          </a>
                          <a className="dropdown-item d-flex align-items-center">
                            <i
                              data-feather="download"
                              className="icon-sm me-2"
                            />{" "}
                            <span className="">Download</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-12 col-xl-5">
                        <h3 className="mb-2">10,500</h3>
                        <div className="d-flex align-items-baseline">
                          <p className="text-danger">
                            <span>-5.6%</span>
                            <i
                              data-feather="arrow-down"
                              className="icon-sm mb-1"
                            />
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-12 col-xl-7">
                        <div id="ordersChart" className="mt-md-3 mt-xl-0" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-baseline">
                      <h6 className="card-title mb-0">No of Person</h6>
                      <div className="dropdown mb-2">
                        <button
                          className="btn p-0"
                          type="button"
                          id="dropdownMenuButton2"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            className="icon-lg text-muted pb-3px"
                            data-feather="more-horizontal"
                          />
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton2"
                        >
                          <a className="dropdown-item d-flex align-items-center">
                            <i data-feather="eye" className="icon-sm me-2" />{" "}
                            <span className="">View</span>
                          </a>
                          <a className="dropdown-item d-flex align-items-center">
                            <i data-feather="edit-2" className="icon-sm me-2" />{" "}
                            <span className="">Edit</span>
                          </a>
                          <a className="dropdown-item d-flex align-items-center">
                            <i data-feather="trash" className="icon-sm me-2" />{" "}
                            <span className="">Delete</span>
                          </a>
                          <a className="dropdown-item d-flex align-items-center">
                            <i
                              data-feather="printer"
                              className="icon-sm me-2"
                            />{" "}
                            <span className="">Print</span>
                          </a>
                          <a className="dropdown-item d-flex align-items-center">
                            <i
                              data-feather="download"
                              className="icon-sm me-2"
                            />{" "}
                            <span className="">Download</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-12 col-xl-5">
                        <h3 className="mb-2">45.50%</h3>
                        <div className="d-flex align-items-baseline">
                          <p className="text-success">
                            <span>+8.2%</span>
                            <i
                              data-feather="arrow-up"
                              className="icon-sm mb-1"
                            />
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-12 col-xl-7">
                        <div id="growthChart" className="mt-md-3 mt-xl-0" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        {/* row */}
        
        {/* row */}
        
        {/* row */}
        <div className="row">
          <div className="col-lg-5 col-xl-4 grid-margin grid-margin-xl-0 stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-baseline mb-2">
                  <h6 className="card-title mb-0">Inbox</h6>
                  <div className="dropdown mb-2">
                    <button
                      className="btn p-0"
                      type="button"
                      id="dropdownMenuButton6"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i
                        className="icon-lg text-muted pb-3px"
                        data-feather="more-horizontal"
                      />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton6"
                    >
                      <a className="dropdown-item d-flex align-items-center">
                        <i data-feather="eye" className="icon-sm me-2" />{" "}
                        <span className="">View</span>
                      </a>
                      <a className="dropdown-item d-flex align-items-center">
                        <i data-feather="edit-2" className="icon-sm me-2" />{" "}
                        <span className="">Edit</span>
                      </a>
                      <a className="dropdown-item d-flex align-items-center">
                        <i data-feather="trash" className="icon-sm me-2" />{" "}
                        <span className="">Delete</span>
                      </a>
                      <a className="dropdown-item d-flex align-items-center">
                        <i data-feather="printer" className="icon-sm me-2" />{" "}
                        <span className="">Print</span>
                      </a>
                      <a className="dropdown-item d-flex align-items-center">
                        <i data-feather="download" className="icon-sm me-2" />{" "}
                        <span className="">Download</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <a className="d-flex align-items-center border-bottom pb-3">
                    <div className="me-3">
                      <img
                        src="../images/reviewer/1.jpg"
                        className="rounded-circle wd-35"
                        alt="user"
                      />
                    </div>
                    <div className="w-100">
                      <div className="d-flex justify-content-between">
                        <h6 className="text-body mb-2">Leonardo Payne</h6>
                        <p className="text-muted tx-12">12.30 PM</p>
                      </div>
                      <p className="text-muted tx-13">
                        Hey! there I'm available...
                      </p>
                    </div>
                  </a>
                  <a className="d-flex align-items-center border-bottom py-3">
                    <div className="me-3">
                      <img
                        src="../images/reviewer/1.jpg"
                        className="rounded-circle wd-35"
                        alt="user"
                      />
                    </div>
                    <div className="w-100">
                      <div className="d-flex justify-content-between">
                        <h6 className="text-body mb-2">Carl Henson</h6>
                        <p className="text-muted tx-12">02.14 AM</p>
                      </div>
                      <p className="text-muted tx-13">
                        I've finished it! See you so..
                      </p>
                    </div>
                  </a>
                  <a className="d-flex align-items-center border-bottom py-3">
                    <div className="me-3">
                      <img
                        src="../images/reviewer/1.jpg"
                        className="rounded-circle wd-35"
                        alt="user"
                      />
                    </div>
                    <div className="w-100">
                      <div className="d-flex justify-content-between">
                        <h6 className="text-body mb-2">Jensen Combs</h6>
                        <p className="text-muted tx-12">08.22 PM</p>
                      </div>
                      <p className="text-muted tx-13">
                        This template is awesome!
                      </p>
                    </div>
                  </a>
                  <a className="d-flex align-items-center border-bottom py-3">
                    <div className="me-3">
                      <img
                        src="../images/reviewer/1.jpg"
                        className="rounded-circle wd-35"
                        alt="user"
                      />
                    </div>
                    <div className="w-100">
                      <div className="d-flex justify-content-between">
                        <h6 className="text-body mb-2">Amiah Burton</h6>
                        <p className="text-muted tx-12">05.49 AM</p>
                      </div>
                      <p className="text-muted tx-13">Nice to meet you</p>
                    </div>
                  </a>
                  <a className="d-flex align-items-center border-bottom py-3">
                    <div className="me-3">
                      <img
                        src="../images/reviewer/1.jpg"
                        className="rounded-circle wd-35"
                        alt="user"
                      />
                    </div>
                    <div className="w-100">
                      <div className="d-flex justify-content-between">
                        <h6 className="text-body mb-2">Yaretzi Mayo</h6>
                        <p className="text-muted tx-12">01.19 AM</p>
                      </div>
                      <p className="text-muted tx-13">
                        Hey! there I'm available...
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-xl-8 stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-baseline mb-2">
                  <h6 className="card-title mb-0">Projects</h6>
                  <div className="dropdown mb-2">
                    <button
                      className="btn p-0"
                      type="button"
                      id="dropdownMenuButton7"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i
                        className="icon-lg text-muted pb-3px"
                        data-feather="more-horizontal"
                      />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton7"
                    >
                      <a className="dropdown-item d-flex align-items-center">
                        <i data-feather="eye" className="icon-sm me-2" />{" "}
                        <span className="">View</span>
                      </a>
                      <a className="dropdown-item d-flex align-items-center">
                        <i data-feather="edit-2" className="icon-sm me-2" />{" "}
                        <span className="">Edit</span>
                      </a>
                      <a className="dropdown-item d-flex align-items-center">
                        <i data-feather="trash" className="icon-sm me-2" />{" "}
                        <span className="">Delete</span>
                      </a>
                      <a className="dropdown-item d-flex align-items-center">
                        <i data-feather="printer" className="icon-sm me-2" />{" "}
                        <span className="">Print</span>
                      </a>
                      <a className="dropdown-item d-flex align-items-center">
                        <i data-feather="download" className="icon-sm me-2" />{" "}
                        <span className="">Download</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-hover mb-0">
                    <thead>
                      <tr>
                        <th className="pt-0">#</th>
                        <th className="pt-0">Project Name</th>
                        <th className="pt-0">Start Date</th>
                        <th className="pt-0">Due Date</th>
                        <th className="pt-0">Status</th>
                        <th className="pt-0">Assign</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Etihad Travels jQuery</td>
                        <td>01/01/2022</td>
                        <td>26/04/2022</td>
                        <td>
                          <span className="badge bg-danger">Released</span>
                        </td>
                        <td>Leonardo Payne</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Etihad Travels Angular</td>
                        <td>01/01/2022</td>
                        <td>26/04/2022</td>
                        <td>
                          <span className="badge bg-success">Review</span>
                        </td>
                        <td>Carl Henson</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Etihad Travels ReactJs</td>
                        <td>01/05/2022</td>
                        <td>10/09/2022</td>
                        <td>
                          <span className="badge bg-info">Pending</span>
                        </td>
                        <td>Jensen Combs</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Etihad Travels VueJs</td>
                        <td>01/01/2022</td>
                        <td>31/11/2022</td>
                        <td>
                          <span className="badge bg-warning">
                            Work in Progress
                          </span>
                        </td>
                        <td>Amiah Burton</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Etihad Travels Laravel</td>
                        <td>01/01/2022</td>
                        <td>31/12/2022</td>
                        <td>
                          <span className="badge bg-danger">Coming soon</span>
                        </td>
                        <td>Yaretzi Mayo</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>Etihad Travels NodeJs</td>
                        <td>01/01/2022</td>
                        <td>31/12/2022</td>
                        <td>
                          <span className="badge bg-primary">Coming soon</span>
                        </td>
                        <td>Carl Henson</td>
                      </tr>
                      <tr>
                        <td className="border-bottom">3</td>
                        <td className="border-bottom">
                          Etihad Travels EmberJs
                        </td>
                        <td className="border-bottom">01/05/2022</td>
                        <td className="border-bottom">10/11/2022</td>
                        <td className="border-bottom">
                          <span className="badge bg-info">Pending</span>
                        </td>
                        <td className="border-bottom">Jensen Combs</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        {/* row */}
      </div>
  
       
        <Footer/>
       
      </div>
    </div>
  </>

  )
}

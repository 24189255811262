import * as React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';


export default function Main() {
  const [pass,setPassword]=useState('')
  const [username,setUsername]=useState('')

  useEffect(() => {
    const access=JSON.parse(localStorage.getItem("admin"))
    if(access!=null)
    {
      window.location.replace("/main");
    }
  }, []);


  const NowLogin = async ()=> {

    const data = new FormData();
    data.append('email',username);
    data.append('pass',pass);


            axios({
              method: 'post',
              url: '/api/user/login.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               
                
            
                
                console.log(s.data)
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const login = async ()=> {
    const data = new FormData();
    data.append('email',username);
    data.append('pass',pass);

            axios({
              method: 'post',
              url: '/api/user/login.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               
                console.log("login")

                if(s.data[0].msg=="disable")
                {
                  alert("Your Account is Disable")
                }else if(s.data[0].msg=="fail")
                  {
                    alert("Failed, try Again !!!!")
                  }else{
                   
                    localStorage.setItem('admin', JSON.stringify(s.data));
                    
                    window.location.replace("/main");

                  }
               
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  return (
    <>
   <section className="login-register pt-6 pb-6">
  <div className="container">
    <div className="log-main blog-full log-reg w-75 mx-auto">
      <div className="row" style={{padding:"70px"}}>
      <div className="col-lg-4 pe-4" >

      </div>
        <div className="col-lg-4 pe-4 card " style={{padding:"40px"}}>
          <h3 className="text-center border-b pb-2">Etihad Travels Login</h3>
         
            <div className="form-group mb-2">
              <input
                type="text"
                name="user_name"
                className="form-control"
                id="fullname"
                placeholder="Username"
                value={username} onChange={(v)=>{ setUsername(v.target.value)}}
              />
            </div>
            <div className="form-group mb-2">
              <input
                type="password"
                name="password_name"
                className="form-control"
                id="password"
                placeholder="Password"
                value={pass} onChange={(v)=>{ setPassword(v.target.value)}}
              />
            </div>
            <br/>
            <div className="comment-btn mb-2 pb-2 text-center border-b">
            <button  className="btn btn-primary"
             onClick={()=>{
              if(username.length<4||pass.length<4)
              {
                alert("Enter Correct Username and password !")
              }else{
                login();
                
              }
              
            }}
            >Submit
                </button>
              
            </div>
            
          
        
        
        </div>
        
      </div>
    </div>
  </div>
</section>


  </>

  )
}
